import $ from "jquery";
const h = $('.header-main-menu');
const hInner = h.find('.hmm-inner');
const header = $('header');
// console.log(header.offset(), header.height());
const vNavigator = $('.vkuster-navigator, .vkuster-end-navigator');
const vMobileMenu = $('#sidebarMenu');
const vOffsetSearch = $('.offcanvas-search');
const vOffsetCatalog = $('.offcanvas-catalog');

header.css('min-height', header.innerHeight());
vNavigator.css('top', `+=${h.innerHeight()}`);
vMobileMenu.css('top', `${hInner.offset().top}px`)
vOffsetSearch.css('top', `${hInner.offset().top}px`)

$(window).scroll(() => {
    if ($(window).scrollTop() + 1 >= header.offset().top + header.height() - h.innerHeight()) {
        const offset = hInner.offsetParent().offset().top - h.offset().top + hInner.innerHeight();
        vMobileMenu.css('top', `${offset}px`);
        vOffsetSearch.css('top', `${offset}px`);
        vOffsetCatalog.css('top', `${h.innerHeight()}px`);
        header.removeClass('shadow');
        h.addClass('position-fixed')
            .removeClass('position-sticky')
            .addClass('shadow');
    } else {
        const offset = hInner.offset().top + hInner.innerHeight();
        vMobileMenu.css('top', `${offset}px`);
        vOffsetSearch.css('top', `${offset}px`);
        vOffsetCatalog.css('top', h.offset().top + h.innerHeight());
        h.removeClass('position-fixed')
            .addClass('position-sticky')
            .removeClass('shadow');
        header.addClass('shadow');
    }
})