import $ from "jquery";
const radiocheck = (checkbox, parent, style) => {
    const items = document.querySelectorAll(checkbox);
    items.forEach((item) => {
        item.addEventListener('change', (e) => {
            const p = item.closest(parent);
            if (item.type === "checkbox" && !e.currentTarget.checked) {
                p.classList.remove(style);
            }
            if (item.type === "radio") {
                document.querySelectorAll(parent).forEach(parent => {
                    parent.classList.remove(style);
                });
            }
            p.classList.add(style);
        });
    });
}

radiocheck('.delivery-time .form-check-input', '.delivery-time', 'checked');

$('.allow-focus').on("click.bs.dropdown", function (e) {
    e.stopPropagation();
    // e.preventDefault();
});