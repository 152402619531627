(function () {
    'use strict'
    const forms = document.querySelectorAll('.needs-validation');
    const smsCode = document.querySelectorAll('.sms');
    Array.prototype.slice.call(smsCode)
        .forEach((input, index, array) => {
            input.addEventListener('keydown', (e) => {
                if (e.target.value.length === e.target.maxLength && e.keyCode !== 8) {
                    if (index + 1 < array.length) {
                        array[index+1].focus();
                    }
                } else if (e.target.value.length === 0 && e.keyCode === 8) {
                    if (index > 0 && array[index-1].value.length > 0) {
                        array[index-1].focus();
                    }
                }
            });
        })
    Array.prototype.slice.call(forms)
        .forEach((form) => {
            form.addEventListener('submit', (e) => {
                if(!form.checkValidity()) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                form.classList.add("was-validated");
            }, false);
        })
})()