import $ from "jquery";
import "slick-carousel";

function slickItem(item, responsive, slidesToShow) {
    $(item).slick({
        slidesToShow: slidesToShow || 4,
        slidesToScroll: 1,
        infinite: false,
        responsive
    })
}

function slickItemPreset(item, preset, slidesToShow) {
    slickItem(item, [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: preset.p480,
                arrows: false
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: preset.p769,
                arrows: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: preset.p600,
                arrows: false
            }
        },
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: preset.p1025,
                arrows: false
            }
        }
    ], slidesToShow)
}

slickItemPreset('.carousel-multiple', {
    p480: 1.25,
    p769: 2.75,
    p600: 2,
    p1025: 3.25
});

slickItemPreset('.carousel-multiple-triple', {
    p480: 1.25,
    p769: 2.25,
    p600: 1,
    p1025: 2.25
}, 3);

slickItemPreset('.carousel-multiple-sixfold', {
    p480: 2.25,
    p769: 3.35,
    p600: 3,
    p1025: 4.25
}, 6)