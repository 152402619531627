import $ from "jquery";

$('.vkuster-file-input').on("change", function () {
    const name = $(this).val().replace(/^.*?([^\\\/]*)$/, '$1');
    let size = this.files[0].size;

    const ext = ['b', 'kb', 'mb', 'gb'];
    let i = 0;
    for(; size > 900; size /= 1024, i++);

    const exactSize = `${(Math.round(size * 100) / 100)} ${ext[i]}`;

    $('.vkuster-file-name').text(name ? name : "Прикрепите файл");
    $('.vkuster-file-size').text(exactSize);
});