// If you're importing tooltips or popovers, be sure to include our Popper.js dependency
import "@popperjs/core/dist/umd/popper.min.js";

// Import the required DOM management plugins first
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../scss/index.scss";

// Features
import "./carousel";
import "./range";
import "./validation";
import "./radiocheck";
import "./upload";
import "./header";
import "./mask";
import "./wow"